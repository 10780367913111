import aws_exports from "./aws-exports";
import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import logo from "./logo.svg";
import "./App.css";
import {
  withAuthenticator,
  Heading,
  UseAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { type AuthUser } from "aws-amplify/auth";
// import UploadZone from "./uploadZone";
import "@aws-amplify/ui-react/styles.css";
import { useFieldset } from "@aws-amplify/ui-react/dist/types/primitives/Fieldset";
import * as Auth from "@aws-amplify/auth";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";

import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Draggable from "react-draggable";

type AppProps = {
  signOut?: UseAuthenticator["signOut"]; //() => void;
  user?: AuthUser;
};

interface AgentFormValues {
  agentusername: string;
  backup: string;
  did: string;
}

interface Agent {
  agentusername: string;
  backup: string;
  did: string;
}

interface ConnectPhones {
  Id: string;
  PhoneNumber: string;
}

interface ConnectUsers {
  Id: string;
  Username: string;
}

Amplify.configure(aws_exports);

const App: React.FC<AppProps> = ({ signOut, user }) => {
  const [isEnabled, setIsEnabled] = useState(true); // Initial state
  const [allCalls, setAllCalls] = useState([]);
  const [cognitoData, setCognitoData] = useState({});

  const [createagentDID, setCreateAgentDID] = useState<ConnectPhones>({
    Id: "",
    PhoneNumber: "",
  });
  const [createBackupAgent, setCreateBackupAgentValue] = useState<ConnectUsers>(
    { Id: "", Username: "" }
  );
  const [createMainAgent, setCreateMainAgentValue] = useState<ConnectUsers>({
    Id: "",
    Username: "",
  });

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [editMainAgent, setEditMainAgentValue] = useState<ConnectUsers>({
    Id: "",
    Username: "",
  });
  const [editBackupAgent, setEditBackupAgentValue] = useState<ConnectUsers>({
    Id: "",
    Username: "",
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [editingAgent, setEditingAgent] = useState<Agent>({
    agentusername: "",
    backup: "",
    did: "",
  });
  const [editagentDID, setEditAgentDID] = useState<ConnectPhones>({
    Id: "",
    PhoneNumber: "",
  });
  const [open, setModalOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [connectPhones, setConnectPhones] = useState<ConnectPhones>({
    Id: "",
    PhoneNumber: "",
  });
  const [connectUsers, setConnectUsers] = useState<ConnectPhones>({
    Id: "",
    PhoneNumber: "",
  });

  const [deleteDIDData, setDeleteDIDData] = useState("");
  const [openCreateDID, setOpenCreateDID] = useState(false);
  // const [agentusername, setAgentUsername] = useState("");
  // console.log(user);
  // let CognitoData = {};
  const getAllData = async () => {
    const usersData = await fetch(
      "https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/getAgentsDID"
    );
    const response = await usersData.json();
    console.log(typeof response);
    if (response.statusCode === 200) {
      console.log(response);
      setAgents(JSON.parse(response.body));
    }
    console.log(response);
  };

  useEffect(() => {
    // Fetch initial value
    // const fetchValue = async () => {
    //   const data = await fetch(
    //     "https://3fm4k63lb9.execute-api.us-east-1.amazonaws.com/staging/OutboundConfig"
    //   ); // Replace with your API Gateway endpoint
    //   const response = await data.json();
    //   setIsEnabled(response[0].Enabled);
    // };
    // fetchValue();
    // // Fetch initial value for calls table
    // const fetchCallsValue = async () => {
    //   const data = await fetch(
    //     "https://3fm4k63lb9.execute-api.us-east-1.amazonaws.com/staging/outboundCalls"
    //   ); // Replace with your API Gateway endpoint
    //   const response = await data.json();
    //   setAllCalls(response.filter((item: any) => item.CallStatus === "Failed"));
    // };
    // fetchCallsValue();
    const fetchValue = async () => {
      const data = await fetch(
        "https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/connectConfig"
      );
      let response = await data.json();
      if (response.statusCode === 200) {
        setConnectPhones(response.Phones);
        setConnectUsers(response.Users);
      }
      console.log(response);

      getAllData();
    };
    fetchValue();
  }, []);

  useEffect(() => {
    const accessToken = Auth.fetchAuthSession().then((data) => {
      // console.log(data);
      setCognitoData(data);
    });
  }, [user]);

  const handleSwitchChange = async (e: any) => {
    const newValue = e.target.checked;
    await fetch(
      "https://3fm4k63lb9.execute-api.us-east-1.amazonaws.com/staging/OutboundConfig",
      {
        headers: { "Content-Type": "Application-json" },
        method: "PUT",
        body: JSON.stringify({ id: "Enabled", Enabled: newValue }),
      }
    );
    setIsEnabled(newValue);
  };
  const [agents, setAgents] = useState<Agent[]>([]);
  const [newAgent, setNewAgent] = useState<AgentFormValues>({
    agentusername: "",
    backup: "",
    did: "",
  });

  useEffect(() => {
    // Fetch agent data from your backend (replace with your API endpoint)
    //   axios.get('/api/agents')
    //     .then(response => setAgents(response.data))
    //     .catch(error => console.error('Error fetching agents:', error));
  }, []);

  const handleNewAgentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAgent({ ...newAgent, [event.target.name]: event.target.value });
  };
  const handleCloseCreateDID = () => {
    setOpenCreateDID(false);
  };

  const handleCreateAgent = async () => {
    // Send new agent data to your backend (replace with your API endpoint)
    // axios.post('/api/agents', newAgent)
    //   .then(response => {
    //     setAgents([...agents, response.data]);
    //     setNewAgent({ agentusername: '', backup: '', did: '' });
    //   })
    //   .catch(error => console.error('Error creating agent:', error));

    setOpenCreateDID(true);
  };

  const handleDeleteAgent = (did: string) => {
    // Send delete request to your backend (replace with your API endpoint)
    // axios.delete(`/api/agents/${agentId}`)
    //   .then(() => {
    //     setAgents(agents.filter(agent => agent.agentusername !== agentId));
    //   })
    //   .catch(error => console.error('Error deleting agent:', error));
    setDeleteDIDData(did);
    setOpenDeleteConfirm(true);
  };

  const handleEditAgent = (agent: Agent) => {
    setEditBackupAgentValue({
      ...editBackupAgent,
      Username: agent.backup,
    });
    setEditAgentDID({
      ...editagentDID,
      PhoneNumber: agent.did,
    });
    setEditMainAgentValue({
      ...editMainAgent,
      Username: agent.agentusername,
    });
    setEditingAgent(agent);
    setModalOpen(true);
  };

  const handleSaveAgent = async (updatedAgent: Agent) => {
    let localUpdatedAgent: Agent = {
      agentusername: editMainAgent.Username,
      did: editagentDID.PhoneNumber,
      backup: editBackupAgent.Username,
    };

    // https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/updateAgent

    try {
      const response = await fetch(
        "https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/updateAgent",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(localUpdatedAgent),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setSnackBarMessage("Agent Updated");
      const data = await response.json();
      // setMessage(data.message || "Data updated successfully");
      // setError(null);
    } catch (err) {
      // setError(err.message);
      // setMessage("");
      setSnackBarMessage("Error Updating data. Please try again ");

      console.log(err);
    } finally {
      // setUsername("");
      // setDid("");
      // setBackup("");
      setOpenSnackBar(true);
      setModalOpen(false);
      setTimeout(getAllData, 200);
    }
  };

  const handleUpdateAgent = (updatedAgent: Agent) => {
    // Send update request to your backend (replace with your API endpoint)
    // axios.put(`/api/agents/${updatedAgent.agentusername}`, updatedAgent)
    //   .then(() => {
    //     setAgents(agents.map(agent => agent.agentusername === updatedAgent.agentusername ? updatedAgent : agent));
    //   })
    //   .catch(error => console.error('Error updating agent:', error));
  };
  function handleClose(
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ): void {
    setModalOpen(false);
  }

  const columns = [
    { field: "did", headerName: "DID", width: 120 },
    { field: "agentusername", headerName: "Main Agent Username", width: 250 },
    { field: "backup", headerName: "Backup Agent Username", width: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params: any) => (
        <>
          {/* <button onClick={() => handleEditAgent(params.row)}>Edit</button> */}
          <Tooltip title="Edit">
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => handleEditAgent(params.row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {/* <button onClick={() => handleDeleteAgent(params.row.agentusername)}>
            Delete
          </button> */}
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => handleDeleteAgent(params.row.did)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  async function handleCloseDeleteConfirm(
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ): Promise<void> {
    setOpenDeleteConfirm(false);
  }

  async function handleDeleteEntry() {
    try {
      const response = await fetch(
        "https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/deleteDID",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(deleteDIDData),
        }
      );

      console.log(response);

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setSnackBarMessage("Deleted");
      const data = await response.json();
      // setMessage(data.message || "Data updated successfully");
      // setError(null);
    } catch (err) {
      // setError(err.message);
      // setMessage("");
      setSnackBarMessage("Error Deleting data. Please try again ");

      console.log(err);
    } finally {
      // setUsername("");
      // setDid("");
      // setBackup("");
      setOpenSnackBar(true);
      setModalOpen(false);
    }
    getAllData();
    setOpenDeleteConfirm(false);
  }

  function PaperComponent(props: any) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  function handleCloseSnackbar(): void {
    setOpenSnackBar(false);
    // setSnackBarMessage("Data Updated Successfully");
  }

  const handleCreateAgentModal = async () => {
    let localUpdatedAgent: Agent = {
      agentusername: createMainAgent.Username,
      did: createagentDID.PhoneNumber,
      backup: createBackupAgent.Username,
    };

    // https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/updateAgent

    try {
      const response = await fetch(
        "https://2lq79iwy3c.execute-api.eu-west-2.amazonaws.com/prod/updateAgent",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(localUpdatedAgent),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setSnackBarMessage("DID Created Successfully");
      const data = await response.json();
      // setMessage(data.message || "Data updated successfully");
      // setError(null);
    } catch (err) {
      // setError(err.message);
      // setMessage("");
      setSnackBarMessage("Error Updating data. Please try again ");

      console.log(err);
    } finally {
      // setUsername("");
      // setDid("");
      // setBackup("");
      setOpenSnackBar(true);
      setModalOpen(false);

      setOpenCreateDID(false);
      setTimeout(getAllData, 500);
    }
    //getAllData();
  };

  const handleCloseCreateModal = () => {
    setOpenCreateDID(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}

        // action={action}
      />
      <div
        style={{
          alignItems: "right",
          justifyContent: "right",
          display: "flex",
        }}
      >
        <Button onClick={signOut}>Sign out</Button>
      </div>
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <h1>Pure Cremation Agents DID Configuration</h1>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {/* Display agents */}
            <div>
              <DataGrid
                rows={agents}
                columns={columns}
                pageSizeOptions={[50]}
                // Adjust as needed
                // rowsPerPageOptions={[5, 10, 20]}
                // checkboxSelection // Enable if needed
                getRowId={(row) => {
                  console.log(row);
                  return row.agentusername;
                }}
              />

              {/* Form for creating a new agent */}
              <form>
                {/* Input fields for agentusername, backup, and did */}
                <button type="button" onClick={handleCreateAgent}>
                  Create DID
                </button>
              </form>
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>

        {/*// @ts-ignore */}
        <Modal open={openCreateDID} onClose={handleCloseCreateDID}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,  -50%)",
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                p: 2,
                maxWidth: "600px",
                minWidth: "500px",
              }}
            >
              <h2 id="modal-modal-title">Create new DID</h2>

              <Autocomplete
                value={createMainAgent}
                onChange={(event, newValue) => {
                  if (newValue) {
                    //@ts-ignore
                    setCreateMainAgentValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const filtered = options.filter((option) =>
                    // @ts-ignore
                    option.Username.toLowerCase().includes(
                      inputValue.toLowerCase()
                    )
                  );

                  return filtered;
                }}
                // @ts-ignore
                options={connectUsers}
                // @ts-ignore
                getOptionLabel={(option) => {
                  console.log(option);
                  // @ts-ignore

                  return option.Username ? option.Username : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Main Agent"
                    variant="outlined"
                  />
                )}
              />
              {/* Edit form with input fields for agentusername, backup, and did */}
              {/* <TextField
                id="agentusername"
                label="Agent Username"
                value={editingAgent?.agentusername || ""}
                onChange={(e) =>
                  setEditingAgent({
                    ...editingAgent,
                    agentusername: e.target.value,
                  })
                }
                fullWidth
                margin="normal"

              /> */}
              <Autocomplete
                value={createBackupAgent}
                style={{ marginBottom: "10px", marginTop: "10px" }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    //@ts-ignore
                    setCreateBackupAgentValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const filtered = options.filter((option) =>
                    // @ts-ignore
                    option.Username.toLowerCase().includes(
                      inputValue.toLowerCase()
                    )
                  );

                  return filtered;
                }}
                // @ts-ignore
                options={connectUsers}
                // @ts-ignore
                getOptionLabel={(option) => {
                  console.log(option);
                  // @ts-ignore

                  return option.Username ? option.Username : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Backup Agent"
                    variant="outlined"
                  />
                )}
              />
              {/* <TextField
                id="editagentBackup"
                label="Backup Agent"
                value={editingAgent?.backup || ""}
                onChange={(e) =>
                  setEditingAgent({
                    ...editingAgent,
                    backup: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              /> */}
              {/* 
              <TextField
                id="editagentDID"
                label="DID"
                value={editingAgent?.did || ""}
                onChange={(e) =>
                  setEditingAgent({
                    ...editingAgent,
                    did: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              /> */}

              <Autocomplete
                style={{ marginBottom: "10px" }}
                value={createagentDID}
                onChange={(event, newValue) => {
                  console.log(event);
                  if (newValue) {
                    //@ts-ignore
                    setCreateAgentDID(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const filtered = options.filter((option) =>
                    // @ts-ignore
                    option.PhoneNumber.toLowerCase().includes(
                      inputValue.toLowerCase()
                    )
                  );

                  return filtered;
                }}
                // @ts-ignore
                options={connectPhones}
                // @ts-ignore
                getOptionLabel={(option) => {
                  console.log(option);
                  // @ts-ignore

                  return option.PhoneNumber ? option.PhoneNumber : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="DID Number"
                    variant="outlined"
                  />
                )}
              />

              {/* ... other input fields */}
              <div style={{ textAlign: "right" }}>
                {/*// @ts-ignore */}
                <Button variant="contained" onClick={handleCreateAgentModal}>
                  Save
                </Button>
                {/*// @ts-ignore */}
                <Button variant="outlined" onClick={handleCloseCreateModal}>
                  Cancel
                </Button>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,  -50%)",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 2,
              maxWidth: "600px",
              minWidth: "500px",
            }}
          >
            <h2 id="modal-modal-title">Edit DID {editingAgent.did} </h2>
            {/* Edit form with input fields for agentusername, backup, and did */}
            {/* <Autocomplete
              style={{ marginTop: "10px" }}
              value={editagentDID}
              onChange={(event, newValue) => {
                console.log(event);
                if (newValue) {
                  //@ts-ignore
                  setEditAgentDID(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                const filtered = options.filter((option) =>
                  // @ts-ignore
                  option.PhoneNumber.toLowerCase().includes(
                    inputValue.toLowerCase()
                  )
                );

                return filtered;
              }}
              // @ts-ignore
              options={connectPhones}
              // @ts-ignore
              getOptionLabel={(option) => {
                console.log(option);
                return option.PhoneNumber;
              }}
              renderInput={(params) => (
                <TextField {...params} label="DID Number" variant="outlined" />
              )}
            /> */}
            <TextField
              id="EditDID"
              label="DID Number"
              value={editingAgent?.did || ""}
              fullWidth
              margin="normal"
              disabled={true}
            />
            <Autocomplete
              value={editMainAgent}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (newValue) {
                  //@ts-ignore
                  setEditMainAgentValue(newValue);
                } else {
                  setEditMainAgentValue({ ...editMainAgent, Username: "" });
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                const filtered = options.filter((option) =>
                  // @ts-ignore
                  option.Username.toLowerCase().includes(
                    inputValue.toLowerCase()
                  )
                );

                return filtered;
              }}
              // @ts-ignore
              options={connectUsers}
              // @ts-ignore
              getOptionLabel={(option) => {
                console.log(option);
                return option.Username;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Main Agent" variant="outlined" />
              )}
            />
            <Autocomplete
              value={editBackupAgent}
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onChange={(event, newValue) => {
                if (newValue) {
                  //@ts-ignore
                  setEditBackupAgentValue(newValue);
                } else {
                  setEditBackupAgentValue({ ...editBackupAgent, Username: "" });
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                const filtered = options.filter((option) =>
                  // @ts-ignore
                  option.Username.toLowerCase().includes(
                    inputValue.toLowerCase()
                  )
                );

                return filtered;
              }}
              // @ts-ignore
              options={connectUsers}
              // @ts-ignore
              getOptionLabel={(option) => {
                console.log(option);
                return option.Username;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Backup Agent"
                  variant="outlined"
                />
              )}
            />
            {/* <TextField
                id="editagentBackup"
                label="Backup Agent"
                value={editingAgent?.backup || ""}
                onChange={(e) =>
                  setEditingAgent({
                    ...editingAgent,
                    backup: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              /> */}
            {/* 
              <TextField
                id="editagentDID"
                label="DID"
                value={editingAgent?.did || ""}
                onChange={(e) =>
                  setEditingAgent({
                    ...editingAgent,
                    did: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              /> */}

            {/* ... other input fields */}
            <div style={{ textAlign: "right" }}>
              {/*// @ts-ignore */}
              <Button variant="contained" onClick={handleSaveAgent}>
                Save
              </Button>
              {/*// @ts-ignore */}
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete Entry
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this entry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*// @ts-ignore */}

          <Button autoFocus onClick={handleCloseDeleteConfirm}>
            No
          </Button>
          {/*// @ts-ignore */}

          <Button onClick={handleDeleteEntry}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withAuthenticator(App);
